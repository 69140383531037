/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css";*/

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/*@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";*/
/*@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";*/
/*@import "~@angular/material/prebuilt-themes/purple-green.css";*/
@import "mixin.scss";


* {
  /*font-family: 'Source Sans Pro', sans-serif;*/
	font-family: 'Heebo', sans-serif;
  /*font-family: 'Roboto', sans-serif;*/
  /*font-family: 'Alegreya Sans', sans-serif;*/
  /*font-family: 'Barlow Semi Condensed', sans-serif;  */
  /*font-family: 'Open Sans', sans-serif;*/
	outline: 0;
}

html,body {
  margin: 0 !important;
  height: 100%;
}

a, a:focus, a:active {
   outline: 0;
}

.full-width {
	width: 100%;
}

.max-width-table {
	max-width: 1000px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.button-lang{
    position: absolute;
    top: 20px;
    right: 90px;
}

.button-lang-xs{@include screen("phone")
   {
    top:5px;
    left:5px;
  }
}

.edit-button {
  position: absolute !important;
  right: 30px !important;
  top: 30px !important;
  box-shadow: none !important;
  background-color: #000 !important;
  color: #FFF !important;
}

.edit-button-xs{@include screen("phone")
  {
  position: absolute !important;
  right: 30px !important;
  top: 30px !important;
  height: 35px !important;
  width: 35px !important;
  box-shadow: none !important;
  background-color: #000 !important;
  color: #FFF !important;
}
}



.admin-menu {
  background-color: #000;
  position: absolute;
  right: 0;
  top: 0px;
  padding: 5px 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-bottom-left-radius: 20px;  
}




.span-button {
  background-color: #ccc;
  padding: 5px 10px;
  cursor: pointer;
}
.span-button:hover {
  background-color: #000;
  color: #FFF;  
}


.mobile-title {
  -webkit-transform: none !important;
  transform: none !important;
  font-size: 1.4em !important;
  letter-spacing: -1px;
  color: #2e3192 !important;
  text-transform: uppercase;
  font-weight: 900 !important;
  padding: 20px 0 0 90px !important;
}


.mat-card-cb mat-card-title {
  color: #2e3192;
  font-size: 2em !important;
  font-weight: 400 !important;

}


.noshadow {
  box-shadow: none !important;
  border: 0 !important;
}

.nocss {
  box-shadow: none !important;
}

.logo-bo {
  width: 300px;
  height: auto;
  padding: 50px 0 0 100px;
}
.logo-bo-xs {@include screen ("phone"){
  width: 250px;
  height: auto;
  padding: 50px 0 0 100px;
}
}

.fl {
  float: left;
}
.cursor-pointer {
  cursor: pointer;
}



.bubble {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  width: 26px;height: 26px;	
  line-height: 26px;
  -webkit-border-radius: 13px;-moz-border-radius: 13px;border-radius: 13px;  
  text-align: center;
  display: block;
  margin-right: 10px;
  font-size: 0.7em;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-header {
  padding-top: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-title {
  font-size: 1.2em !important;
  line-height: 0.5em;
  font-weight: 300;
}

mat-spinner {
  margin: 0 auto;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled){
	background-color: transparent;
}

.bg-cover {
  -webkit-background-size: cover;
  background-size: cover;
}


.nw-editor__src, .nw-editor__res {
  padding: 10px !important;
}


.responsive {
  max-width: 100%;
  height: auto;
}


.searchDialog, .footerDialog, .filterEventsDialog {
  background-color: rgba(46, 49, 146, 0.9);
}
.searchDialogPanel mat-dialog-container {
  background-color: #fff200 !important;
  background: #fff200 !important;
  color: #2e3192;
  font-size: 1.5em;
  font-weight: bold;
}
.searchDialogPanel h3 {
  line-height: 1.1em;
  font-size: 1em;
  margin: 10px 0 20px;
  padding: 0;
}

.filterEventsDialogPanel .mat-mdc-dialog-container {
  padding: 0;
}
.filterEventsDialogPanel,.filterEventsDialogPanel .mat-mdc-dialog-container {
  background-color: #2e3192;  
}


@media print{
  .doNotPrint {
    display:none !important
  } 

  .mode-A4 {
    box-shadow: none !important;
  }

  .logofortouringpage {
    margin-left: 0; 
    padding: 10px 10px 0;
  }
}



.darkblue { color: #2e3192 } .bg-darkblue { background-color: #2e3192 }
.blue { color: #0c6bad }
.bleuciel { color: #009de0 }
.bluegreen { color: #0099a2 }
.lightblue { color: #d6dded; }

.pianoblue { color: #0099a1; } .bg-pianoblue, .pianolink{ background-color: #0099a1 !important }; .bg-piano { background-color: #0099a1; }
.pianolink:hover { background-color: #802b43 }
.pianobrown { color: #802b43; } .bg-pianoblue { background-color: #802b43; }
.pianohover:hover { background-color: #0099a1; color: #FFF; }

.compopink { color: #cd5393; } .bg-compopink, .compolink { background-color: #cd5393; } 
.compolink:hover { background-color: #6f6f6f }
.compogrey { color: #6f6f6f; } .bg-compogrey { background-color: #6f6f6f; }
.compohover:hover, .bg-compo { background-color: #cd5393; color: #FFF }

.comdu-link { background-color: #8cb63c !important;}
.comdu-link:hover { background-color: #2e3192 !important;}
.hover-condu:hover { background-color: #8cb63c; color: #FFF; }

.cello-link, .bg-cello, .bg-viola, .bg-violin { background-color: #009de0 !important;}
.cello-link:hover { background-color: #dc911b !important;}
.hover-cello:hover { background-color: #009de0 !important; color: #FFF; }

.marron { color:#dc911b; }
.green { color: #16979e; }
.green2 { color:#8cb63c; } .bg-green2 { background-color: #8cb63c }
.lightgreen { color: #e5f0f0 }
.grey{ color:#a1a5a4; }
.purple { color:#802b43; }
.red {color:#e90b18;} .bg-red { background-color: #e90b18 !important}
.yellow{color: #fff200;} .bg-yellow { background-color:#fff200  }


.black { color:#000; } .bg-black { background-color: #000 }


.fx-col {display: flex !important;flex-direction: column;}
.fx-row {display: flex !important;flex-direction: row;}
.fx-wrap { display: flex !important; flex-wrap: wrap;}
.fx-center-center { display: flex; justify-content: center; align-items: center;}
.fx-start-start { display: flex; justify-content: flex-start; align-items: flex-start;}
.fx-end-end { display: flex; justify-content: flex-end; align-items: flex-end;}
.fx-start-center { display: flex; justify-content: flex-start; align-items: center;}
.fx-start-end { display: flex; justify-content: flex-start; align-items: flex-end;}
.fx-center-start { display: flex; justify-content: center; align-items: flex-start;}
.fx-end-start { display: flex; justify-content: flex-end; align-items: flex-start;}
.fx-end-center { display: flex; justify-content: flex-end; align-items: center;}
.fx-end-stretch { display: flex; justify-content: flex-end; align-items: stretch;}
.fx-center-stretch {display: flex; justify-content: center; align-items: stretch;}

.fx-center { display: flex; align-items: center;}
.fx-gap-1 { display: flex; gap: 1px }
.fx-gap-5 { display: flex; gap: 5px }
.fx-gap-10 { display: flex; gap: 10px }
.fx-gap-20 { display: flex; gap: 20px }
.fx-wrap { flex-wrap:wrap}
.fx-row-wrap { display:flex; flex-direction:row; flex-wrap:wrap}
.fx-ac-center { display: flex; align-content: center}
.fx-ai-center { display: flex; align-items: center}
.fx-ai-start { display: flex; align-items: flex-start}
.fx-ai-end { display: flex; align-items: flex-end;}
.fx-jc-center { display: flex; justify-content: center;}
.fx-jc-start { display: flex; justify-content: flex-start}
.fx-jc-end { display: flex; justify-content: flex-end}
.fx-jc-sb { display: flex; justify-content: space-between;}
.fx-sb { display: flex !important; justify-content: space-between; }
.fx-start-start { display: flex; justify-content: flex-start; align-items: flex-start;}
.fx-start-stretch { display: flex; justify-content: flex-start; align-items: stretch;}
.fx-as { display: flex; align-self: flex-start;}
.fx-as-col { display: flex; align-self:flex-start; flex-direction: column;}

.fx-1 { flex: 1;}
.fx-100px { flex: 100px;}
.fx-10 { display:flex; flex: 10%; }
.fx-15 { display:flex; flex: 15%; }
.fx-19 { display:flex; flex: 19%; }
.fx-24 { display:flex; flex: 24%; }
.fx-20 { display:flex; flex: 20%; }
.fx-25 { display:flex; flex: 25%; }
.fx-30 { display:flex; flex: 30%; }
.fx-33 { display:flex; flex: 33%; }
.fx-40 { display:flex; flex: 40%; }
.fx-45 { display:flex; flex: 45%; }
.fx-49 { display:flex; flex: 49%; }
.fx-50 { display:flex; flex: 50%; }
.fx-50px { display:flex; flex: 50px; }
.fx-60 { display:flex; flex: 60%; }
.fx-70 { display:flex; flex: 70%; }
.fx-78 { display:flex; flex: 78%; }
.fx-80 { display:flex; flex: 80%; }
.fx-90 { display:flex; flex: 90%; }
.fx-100 { display:flex; flex: 100%; }
.fx-calc-25-5px { display:flex; flex: calc(25% - 5px); }
.fx-calc-50-3px { display:flex; flex: calc(50% - 3px); }
.fx-calc-12-1px { display:flex; flex: calc(12.5% - 1px); }
.fx-calc-33-1px { display:flex; flex: calc(33% - 1px); }

.fx-fill { display: flex; flex: 1 1 100%;}
.fx-fullscreen { display: flex; height: 100%; z-index: 9999; }
.fx-row-xs { @include screen("phone"){display: flex !important;flex-direction: row;}}
.fx-wrap-xs { @include screen("phone"){ display: flex !important; flex-wrap: wrap;}}
.fx-start-start-xs { @include screen("phone"){ display: flex; justify-content: flex-start; align-items: flex-start;}}
.fx-start-center-xs { @include screen("phone"){ display: flex; justify-content: flex-start; align-items: center;}}
.fx-center-center-xs { @include screen("phone"){ display: flex; justify-content: center; align-items: center;}}
.fx-end-start-xs { @include screen("phone"){ display: flex; justify-content: flex-end; align-items: flex-start;}}
.fx-end-center-xs { @include screen("phone"){ display: flex; justify-content: flex-end; align-items: center;}}
.fx-ai-start-xs { @include screen("phone"){ display: flex; align-items: flex-start;} }
.fx-ai-center-xs { @include screen("phone"){display: flex; align-items: center;}}
.fx-fullscreen-xs {@include screen("phone"){display: flex ; height: 100% ; z-index: 9999;}}
.fx-col-xs { @include screen("phone"){ display: flex; flex-direction: column;} }
.fx-fill-xs { @include screen("phone"){ display: flex; flex: 1 1 100%;}}
.fx-gap-20-xs {@include screen("phone"){ display: flex; gap: 20px;}}
.fx-gap-30-xs {@include screen("phone"){ display: flex; gap: 30px;}}
.fx-0-xs {@include screen("phone"){ display: flex; flex: 0%; } }
.fx-10-xs {@include screen("phone"){ display: flex; flex: 10%; } }
.fx-15-xs {@include screen("phone"){ display: flex; flex: 15%; } }
.fx-20-xs {@include screen("phone"){ display: flex; flex: 20%; } }
.fx-33-xs {@include screen("phone"){ display: flex; flex: 33%; } }
.fx-40-xs {@include screen("phone"){ display: flex; flex: 40%; } }
.fx-45-xs {@include screen("phone"){ display: flex; flex: 45%; } }
.fx-50-xs {@include screen("phone"){ display: flex; flex: 50%; } }
.fx-60-xs {@include screen("phone"){ display: flex; flex: 60%; } }
.fx-80-xs {@include screen("phone"){ display: flex; flex: 80%; } }
.fx-85-xs {@include screen("phone"){ display: flex; flex: 85%; } }
.fx-100-xs {@include screen("phone"){ display: flex; flex: 100%; } }

.fx-calc-25-1px-xs {@include screen("phone") { display:flex; flex: calc(25% - 1px); } }

.padding-5-xs{@include screen("phone") {padding:5px;}}
.padding-10-xs{@include screen("phone") {padding:10px;}}
.padding-20-xs{@include screen("phone") {padding:20px;}}

.hide-mobile { @include screen("phone"){ display: none !important; } }
.hide-desktop { @include screen("desktop"){ display: none !important } }


.mt-20 { margin-top: 20px !important;}
.my-100px { margin: 0 100px !important}